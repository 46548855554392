import { isConsumidorFinal } from "../../Funciones/utils"
import moment from "moment"
import store from "../store"

export const payConditions = [
  "Contado",
  "Cuenta corriente",
  "Transferencia Bancaria",
  "Tarjeta de crédito",
  "Tarjeta de débito",
  "vto 5 dias",
  "vto 10 dias",
  "vto 15 dias",
  "vto 20 dias",
  "vto 30 dias",
  "vto 60 dias",
  "vto 90 dias",
  "otra",
]
// export const billTypes=["Factura A",
// "Factura B",
// "Factura C","Nota de credito A","Nota de credito B",
// "Factura no valida en AFIP","Nota de credito no valida en AFIP",
// "Factura C","Factura M","Factura E"]

const defaultState = {
  customer: {},
  paymentsList: [],
  reserva: {},
  loading: false,
  loadingCuitSearch: false,
  itemsList: [],
  tipoPrecio:'camping',
  toggleBilling: false,
  finalConsumerLimit: 13115,
  loadingBill: false,
  payConditions,
  finalConsumerLimitElectronic: 26230,
  billList: [],
  type: "",
  billInfo: {
    typeBill: "Factura B",
    payCondition: "Contado",
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    sendEmail: false,
    total: 0,
    dateBill: moment().format("YYYY-MM-DD"),
  },
}

export default function reducer(state = defaultState, action) {
    const payload=action.payload;
    const billList=state.billList
    const reserva=state.reserva

  switch (action.type) {
    case "SET_LOADING_BILL":
      return { ...state, loadingBill: payload }
    case "SET_TYPE_BILL":
        return { ...state, billInfo: { ...state.billInfo, typeBill: payload } }
    case "SET_BILLING_INFO":
      return { ...state, ...payload }
    case "SET_RESERVA":
        return {...state,reserva:payload};
    case "SET_LOADING_CUIT_SEARCH":
      return { ...state, loadingCuitSearch: payload }
    case "SET_TOGGLE_BILLING":
      return { ...state, toggleBilling: payload }
    case "SET_PAYMENT_TO_BILL_NOW":
      return { ...state, paymentToBillNow: payload }
    case "SET_PAYMENT_TO_BILL":
      return { ...state, paymentToBill: payload }
    case "SET_BILL_LIST":
      return { ...state, billList: payload }
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: payload,
        billInfo: {
          ...state.billInfo,
          sendEmail: payload.email ? true : false,
        },
      }
    case "SET_BILL_INFO":
      if (
        payload.typeBill === "Factura C" ||
        payload.typeBill === "Factura B"
      ) {
        const itemsList = state.itemsList.map(item => {
          item.iva = payload.typeBill === "Factura C" ? 0 : 21
          return item
        })
        return {
          ...state,
          itemsList,
          billInfo: { ...state.billInfo, ...payload },
        }
      } else {
        return { ...state, billInfo: { ...state.billInfo, ...payload } }
      }
    case "CHANGE_DESCRIPTION":
      return {
        ...state,
        itemsList: state.itemsList.map(item =>
          item.paymentId === payload.paymentId
            ? { ...item, description: payload.description }
            : item
        ),
      }
    case "SET_PAYMENTS_LIST":
      const servicesList = reserva.servicesList.filter(service =>
        service.paymentsList.find(payment =>
          payload.find(
            payment2 => payment2.paymentId === payment.paymentId
          )
        )
      )
      const nights =
        billList.length > 0
          ? reserva.servicesList.length > 0
            ? servicesList.reduce((acc, service) => acc + service.quantity, 0)
            : 1
          : 1
      const dateFrom =
        billList.length > 0
          ? calculateDateFrom(reserva.checkinEstimated, billList)
          : moment(reserva.checkinEstimated).format("YYYY-MM-DD")
      const dateTo =
        billList.length > 0
          ? calculateDateTo(dateFrom, nights, billList)
          : moment(reserva.checkoutEstimated).format("YYYY-MM-DD")
      const payConditionAux =
        payload.length > 0
          ? choosePayCondition(payload[0].method)
          : "Contado"
      const type = state.tipoPrecio
      //days to add reflects the number of days to add to calculate fecha entrada
      let daysToAdd = servicesList.reduce(
        (acc, service) => [...acc, service.quantity + acc[acc.length - 1]],
        [0]
      )
      //Item list contain description and quantity for each item in the bill
      const itemsList =
        servicesList.length > 0
          ? servicesList.map((service, index) => {
              const item = {
                price: service.cost,
                paymentId: service.serviceId,
                description: `Estadia ${type} ${
                  service.description
                }.\nFecha de entrada ${moment(dateFrom)
                  .add(daysToAdd[index], "days")
                  .format("DD/MM/YYYY")}`,
                quantity: 1,
                iva: state.typeBill == ("Factura C" || "Factura T") ? 0 : 21,
              }
              return item
            })
          : []
      return {
        ...state,
        paymentsList: payload,
        itemsList,
        billInfo: {
          ...state.billInfo,
          payCondition: payConditionAux,
          dateFrom,
          dateTo,
          total: payload.reduce(
            (total, payment) => total + parseInt(payment.amount),
            0
          ),
        },
      }
    default:
     return state
  }
}
const choosePayCondition = payFormat => {
  switch (payFormat.toLowerCase()) {
    case "Mercadopago".toLowerCase():
      return "otra"
    case "Tarjeta-Web".toLowerCase():
      return "Tarjeta de crédito"
    case "Tarjeta de débito".toLowerCase():
      return "Tarjeta de débito"
    case "Tarjeta de crédito".toLowerCase():
      return "Tarjeta de crédito"
    case "transferencia bancaria":
      return "Transferencia Bancaria"
    default:
      return "Contado"
  }
}
const calculateDateFrom = (checkinEstimated, billList) => {
  if (billList.length > 0) {
    const mostRecentBill = billList.sort((a, b) =>
      moment(b.date).diff(moment(a.date))
    )[0]
    const dateFrom = moment(mostRecentBill.dateTo).format("YYYY-MM-DD")
    return dateFrom
  } else {
    return checkinEstimated
  }
}
const calculateDateTo = (from, nights, billList) => {
  if (billList.length > 0) {
    const mostRecentBill = billList.sort((a, b) =>
      moment(b.date).diff(moment(a.date))
    )[0]
    const dateTo = moment(mostRecentBill.dateTo)
      .add(nights, "days")
      .format("YYYY-MM-DD")
    return dateTo
  } else {
    return moment(from)
      .add(1, "days")
      .format("YYYY-MM-DD")
  }
}
