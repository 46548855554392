import moment from 'moment';

const spaceFixed = () => (
 `







`)


//Checkin signature version with html
export const CheckinSignature = (ingreso) => (
    `
    <html>
    <head>
    <style>
    body{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }
    </style>
    </head>
    <body>
    <div style="width:100%;">
    <div style="width:100%;text-align:center;">
    <h1>CHECKIN</h1>
    </div>
    <div style="width:100%;text-align:center;">
    <h2>${moment().format('DD/MM/YYYY HH:mm')}</h2>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>PERSONAS</h3>
    </div>
    <div style="width:100%">
    <table style="width:100%;">
    <tr>
    <th>Nombre</th>
    <th>DNI</th>
    <th>Edad</th>
    </tr>
    ${ingreso.customersList.map(x=>(
    `<tr>
    <td>${x.fullName}</td>
    <td>${x.dni==null?"-":x.dni}</td>
    <td>${(x.age==0||!x.age)?"-":x.age}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    ${ingreso.vehiclesList.length>0?`
    <div style="width:100%;text-align:center;">
    <h3>VEHICULOS</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <table style="width:100%;">
    <tr>
    <th>PATENTE</th>
    <th>TIPO</th>
    <th>COLOR</th>
    </tr>
    ${ingreso.vehiclesList.map(x=>(
    `<tr>
    <td>${x.plate}</td>
    <td>${x.brand}</td>
    <td>${x.color}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    `:` <div style="width:100%;text-align:center;">SIN VEHICULOS</div>`}

    <div style="width:100%;text-align:center;">
    <h3>Lugar: ${ingreso.roomsList.map(x=>x.number).join(", ")}</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Cantidad de noches: ${ingreso.nights}</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Firma__________________________________________</h3>
    </div>
    <div style="width:100%;text-align:center;">
    <h3>Aclaracion_____________________________________</h3>
    </div>
    <br/>
    </div>
    </body>
    </html>
    `)
//Checkin signature version with html
export const CheckoutSignature = (ingreso) => (
    `
    <html>
    <head>
    <style>
    body{
        font-family: Arial, Helvetica, sans-serif;
        text-align:center;
        font-size: 12px;
    }
    tr{
        text-align:center;
    }
    </style>
    </head>
    <body>
    <div style="width:100%;">
    <div style="width:100%">
    <table style="width:100%;">
    <tr>
    <th>Nombre</th>
    <th>DNI</th>
    </tr>
    <tr>
    <td>${ingreso.customersList[0].fullName}</td>
    <td>${ingreso.customersList[0].dni==null?"-":ingreso.customersList[0].dni}</td>
    </tr>
    </table>
    </div>
    ${ingreso.vehiclesList.length>0?`
    <div style="width:100%;text-align:center;">
    </div>
    <div style="width:100%;text-align:center;">
    <table style="width:100%;">
    <tr>
    <th>Patente</th>
    <th>Tipo</th>
    <th>Color</th>
    </tr>
    ${ingreso.vehiclesList.map(x=>(
    `<tr>
    <td>${x.plate}</td>
    <td>${x.brand}</td>
    <td>${x.color}</td>
    </tr>`
    )).join('\n')}
    </table>
    </div>
    `:``}

    <div style="width:100%;text-align:center;">
        ${ingreso.roomsList.length>0?
        `<h3>Lugar: ${ingreso.roomsList.map(x=>x.number).join(", ")}</h3>`:
        ``}
    </div>
    <div style="width:100%;text-align:center;">
    <h5>Por favor no retirarse definitivamente del complejo sin antes avisar su partida</h5>
    </div>
    </div>
    </body>
    </html>
    `)

//Html for the bill
export const TicketFacturaB= (nombreFantasia,razonSocial,CUIT,domicilio,inicioDeActividades,ingresosBrutos,numeroFactura,fechaEmision,formaPago,listaProductos,CAE,vencimientoCAE,afipQR) => 
`
<!DOCTYPE html>
<html lang="en">

<head>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: 'PT Sans', sans-serif;
        }

        @page {
            size: 2.8in 11in;
            margin-top: 0cm;
            margin-left: 0cm;
            margin-right: 0cm;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;

        }

        h1 {
            text-align: center;
            vertical-align: middle;
        }

        #logo {
            width: 60%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            padding: 5px;
            margin: 2px;
            display: block;
            margin: 0 auto;
        }

        header {
            width: 100%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            vertical-align: middle;
        }

        .items thead {
            text-align: center;
        }

        .center-align {
            text-align: center;
        }

        .bill-details td {
            font-size: 12px;
        }

        .receipt {
            font-size: medium;
            font-weight: bold;
        }

        .items .heading {
            font-size: 12.5px;
            text-transform: uppercase;
            border-top:1px solid black;
            margin-bottom: 4px;
            border-bottom: 1px solid black;
            vertical-align: middle;
        }

        .items thead tr th:first-child,
        .items tbody tr td:first-child {
            width: 47%;
            min-width: 47%;
            max-width: 47%;
            word-break: break-all;
            text-align: left;
        }

        .items td {
            font-size: 12px;
            text-align: right;
            vertical-align: bottom;
        }

        .price::before {
            font-family: Arial;
            text-align: right;
        }

        .sum-up {
            text-align: right !important;
        }
        .total {
            font-size: 13px;
            border-top:1px dashed black !important;
            border-bottom:1px dashed black !important;
        }
        .total.text, .total.price {
            text-align: right;
        }
        .total.price {
            font-size: 16px;
        }
        .line {
            border-top:1px solid black !important;
        }
        .heading.amount {
            width: 10%;
        }
        .heading.qty {
            width: 5%
        }
        p {
            padding: 1px;
            margin: 0;
        }
        section, footer {
            font-size: 12px;
        }
        .qr-code{
            width:100px;
            height:auto;
            margin:auto;
          }
    </style>
</head>

<body>
    <header>
        <span class="receipt">${nombreFantasia}</span>
    </header>
    <br/>
    <section>
        <p>
            <span>${razonSocial}</span>
        </p>
        <p>
            CUIT : <span>${CUIT}</span>
        </p>
        <p>
            Ing. Brutos : <span>${ingresosBrutos}</span>
        </p>
        <p>
            Domicilio : <span>${domicilio}</span>
        </p>
        <p>
            Inicio de actividades : <span>${inicioDeActividades}</span>
        </p>
        <p>
            IVA RESPONSABLE INSCRIPTO
        </p>

</section>
    <table class="bill-details">
        <tbody>
            <tr>
                <td>Fecha : <span>${moment().format('DD/MM/YYYY HH:mm')}</span></td>
                <td>Nro : <span>${numeroFactura}</span></td>
            </tr>
            <tr>
                <th class="center-align" colspan="2"><span class="receipt">Factura B (Cod. 6)</span></th>
            </tr>
        </tbody>
    </table>
    
    <table class="items">
        <thead>
            <tr>
                <th class="heading name">Descripcion</th>
                <th class="heading amount">Precio</th>
            </tr>
        </thead>
       
        <tbody>
        ${listaProductos.map(producto => {
            return `<tr>
            <td>${producto.description}</td>
            <td class="price">${producto.price}</td>
            </tr>`
            }).join('')}
            <tr>
                <td colspan="1" class="sum-up line">Subtotal</td>
                <td class="line price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</td>
            </tr>
            <tr>
                <th colspan="1" class="total text">Total</th>
                <th class="total price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</th>
            </tr>


        </tbody>
    </table>
    <section>
        <p>
            Forma de pago : <span>${formaPago}</span>
        </p>
        <p>
            Fecha de emision : <span>${moment(fechaEmision).format("DD/MM/YYYY")}</span>
        </p>
        <p>
            CAE : <span>${CAE}</span>
        </p>
        <p>
            Vencimiento CAE : <span>${vencimientoCAE}</span>
        </p>
        ${afipQR?`
        <div class="qr-code">
            <img width="100px" src="${afipQR}"/>
        </div>`:''}
    </section>
    <footer style="text-align:center">
        <p>¡Gracias por su visita!</p>
    </footer>
</body>

</html>`

export const TicketX= (fechaEmision,formaPago,listaProductos) => 
`
<!DOCTYPE html>
<html lang="en">

<head>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: 'PT Sans', sans-serif;
        }

        @page {
            size: 2.8in 11in;
            margin-top: 0cm;
            margin-left: 0cm;
            margin-right: 0cm;
        }

        table {
            width: 100%;
        }

        tr {
            width: 100%;

        }

        h1 {
            text-align: center;
            vertical-align: middle;
        }

        #logo {
            width: 60%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            padding: 5px;
            margin: 2px;
            display: block;
            margin: 0 auto;
        }

        header {
            width: 100%;
            text-align: center;
            -webkit-align-content: center;
            align-content: center;
            vertical-align: middle;
        }

        .items thead {
            text-align: center;
        }

        .center-align {
            text-align: center;
        }

        .bill-details td {
            font-size: 12px;
        }

        .receipt {
            font-size: medium;
            font-weight: bold;
        }

        .items .heading {
            font-size: 12.5px;
            text-transform: uppercase;
            border-top:1px solid black;
            margin-bottom: 4px;
            border-bottom: 1px solid black;
            vertical-align: middle;
        }

        .items thead tr th:first-child,
        .items tbody tr td:first-child {
            width: 47%;
            min-width: 47%;
            max-width: 47%;
            word-break: break-all;
            text-align: left;
        }

        .items td {
            font-size: 12px;
            text-align: right;
            vertical-align: bottom;
        }

        .price::before {
            font-family: Arial;
            text-align: right;
        }

        .sum-up {
            text-align: right !important;
        }
        .total {
            font-size: 13px;
            border-top:1px dashed black !important;
            border-bottom:1px dashed black !important;
        }
        .total.text, .total.price {
            text-align: right;
        }
        .total.price {
            font-size: 16px;
        }
        .line {
            border-top:1px solid black !important;
        }
        .heading.amount {
            width: 10%;
        }
        .heading.qty {
            width: 5%
        }
        p {
            padding: 1px;
            margin: 0;
        }
        section, footer {
            font-size: 12px;
        }
        .qr-code{
            width:100px;
            height:auto;
            margin:auto;
          }
    </style>
</head>

<body>
    <header>
        <span class="receipt">Recibo de pago</span>
    </header>
    <br/>
    <table class="bill-details">
        <tbody>
            <tr>
                <th class="center-align" colspan="1"><span class="receipt">X</span></th>
            </tr>
        </tbody>
    </table>
    
    <table class="items">
        <thead>
            <tr>
                <th class="heading name">Descripcion</th>
                <th class="heading amount">Precio</th>
            </tr>
        </thead>
       
        <tbody>
        ${listaProductos.map(producto => {
            return `<tr>
            <td>${producto.description}</td>
            <td class="price">${producto.price}</td>
            </tr>`
            }).join('')}
            <tr>
                <td colspan="1" class="sum-up line">Subtotal</td>
                <td class="line price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</td>
            </tr>
            <tr>
                <th colspan="1" class="total text">Total</th>
                <th class="total price">${listaProductos.map(producto => producto.price).reduce((a,b) => a + parseInt(b), 0)}</th>
            </tr>


        </tbody>
    </table>
    <section>
        <p>
            Forma de pago : <span>${formaPago}</span>
        </p>
        <p>
        Fecha y hora : <span>${moment(fechaEmision).format("DD/MM/YYYY HH:mm")}</span>
        </p>
        <br/>
        <br/>
        <p style="text-align:center">
            DOCUMENTO NO VALIDO COMO FACTURA
        </p>
     
    </section>
    <footer style="text-align:center">
        <p>¡Gracias por su visita!</p>
    </footer>
</body>

</html>`
