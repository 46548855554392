import React from "react"
import moment from 'moment'
import {Col,Row} from 'reactstrap';
import {DevolverComponentes,ElegirLabel,DevolverComponentesConServicios, ElegirColorSemantic} from "../../../Funciones/utils"
import { Card,Icon } from 'semantic-ui-react'
import { useDispatch,useSelector } from 'react-redux'

//ACTIONS
import setModal from '../../../redux/actions/setModalReserva'
import setReserva from '../../../redux/actions/setReserva'

export const GetCard = ({ingreso,i}) => {
    const dispatch = useDispatch(x=>x)
    const {tipoPrecio} = useSelector(state => state.mainReducer)
   return (
                 <Card color={ElegirColorSemantic(ingreso.state,ingreso.isDeudor)} key={i} onClick={async ()=>{
                     if (ingreso.roomsList.length>0&&
                        tipoPrecio!=ingreso.roomsList[0].type) {
                         alert(`Error: estas intentando abrir un registro que no es de ${tipoPrecio}`)
                     }
                     else{
                         dispatch(setModal(true));
                         dispatch(setReserva(ingreso));

                     }
                     }}>
                 {ElegirLabel(ingreso.state,ingreso.nightsDue)}
                    <Card.Content>
                    <Card.Header>{ingreso.customersList[0].fullName}</Card.Header>
                    <Card.Meta style={{color:'black',fontSize:'1.1rem'}}>{`${Object.keys(DevolverComponentesConServicios(DevolverComponentes(ingreso),ingreso.servicesList)).reduce((acom,current)=>(DevolverComponentesConServicios(DevolverComponentes(ingreso),ingreso.servicesList)[current]!=0?`${DevolverComponentesConServicios(DevolverComponentes(ingreso),ingreso.servicesList)[current]} ${current} `:"")+acom,"")}`}</Card.Meta>
                       <Card.Meta>{`${ingreso.roomsList.reduce((acom,current)=>`${current.number} ${acom}`,"")??"Sin Lugar"}`}</Card.Meta>
                       <Card.Meta>{` ${ingreso.vehiclesList.map(x=>`${x.brand??''} ${x.plate??''} ${x.color??''}`).toString()}`}</Card.Meta>
                      <Card.Description>
                        <Row>
                          <Col xs={12}>
                            {moment(ingreso.checkinEstimated).format("DD MMM").split('.').join("")}
                            <Icon name="arrow right"></Icon>
                            {moment(ingreso.checkoutEstimated).format("DD MMM").split('.').join("")}
                            {` (${ingreso.nights} ${ingreso.nights==1?"noche":"noches"})`}
                          </Col>
                        </Row>
                      </Card.Description>
                    </Card.Content>
                </Card>
          )
}



export default GetCard

