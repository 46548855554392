import React from 'react'
import { takeLatest,put,select, takeLeading } from 'redux-saga/effects'
import moment from 'moment'
import {API,graphqlOperation} from 'aws-amplify';
import {reservationList,getStatusList,roomsList,getAllPrices,getPrices,getAllCashMovements,lastBuild,lastMovement,reservation,search,getUserInfo,lastChange,getCheckoutList,getMonthStatistics,billsByReservation,priceLastChange} from "../graphql/queries"
import {addPrice,cancelService,addCashMovement,makeBill,modifyPriceLastChange,modifyLastChange} from "../graphql/mutations"
import {ListaFechas, MejorarIngreso,CambiarEstadoReserva,CalcularCheckoutTime,ObtenerListaPagosFechas, Normalizar,connectPrinter, printPOS,billlToCreditNote} from '../Funciones/utils'
import GetCard from '../components/dormis/main/getCard'

export const ArreglarServicesList=(servicesList)=>{
  servicesList=servicesList.map(y=>({...y,paymentsList:y.paymentsList?y.paymentsList:[]}));
  servicesList=servicesList.map(x=>{
      return ({...x,
      perNight:x.quantity==0?x.cost:Math.round(x.cost/x.quantity),
      paid:x.paymentsList.filter(x=>!x.isRefund).reduce((a,b,i)=>a+b.amount,0),
      due:x.cost-x.paymentsList.filter(x=>!x.isRefund).reduce((a,b,i)=>a+b.amount,0)+x.paymentsList.filter(x=>x.isRefund).reduce((a,b,i)=>a+b.amount,0)})})
      return servicesList
  }

export const GetPercentPayed=(servicesList)=>{
    servicesList=servicesList.filter(x=>!x.canceled)
    //Calcular porcentaje pagado
    let percentPayed=0
    let costoTotal=servicesList.length!=0?servicesList.map(x=>x.cost).reduce((a,b)=>a+b):0;
    let pagado=servicesList.length!=0?servicesList.map(x=>x.paymentsList.map(y=>y.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b):0;
    percentPayed=pagado/costoTotal;
    percentPayed=Math.round(percentPayed*100);
    if (costoTotal==0) {
        percentPayed=100;
    }
    //Si no hay nada pagado que diga que no pago nada
    if (servicesList.length==0){
        percentPayed=0;
    }
    if (percentPayed>100) {
        percentPayed=100;
    }
    return percentPayed;
}

function* reloadAll() {
  yield put({type:"MAIN_LOADING",payload:true});
  yield *reloadRooms();
  const hasLocalStorage=false//yield *reloadFromLocalStorage();
  //Si existe local storage cargar asyncronico
  //localStorage.setItem('listaGeneralReservas', JSON.stringify([]))

  if (!hasLocalStorage) {
    //alert('true')
    yield *reloadReservations();
    //alert('not has localstorage')
    yield put({type:"MAIN_LOADING",payload:false});
  }
  else{
    //alert('false')

    yield put({type:"MAIN_LOADING",payload:false});
    yield *reloadReservations();
  }
 
 }
 function* reloadReservations(payload) {
  const {lastChange:lastChangeAux,tipoPrecio:type,loadingBar,reserva,modal}=yield select(x=>x.mainReducer)
  if (!type||loadingBar) {
    yield put({type:"SET_LOADING_BAR",payload:false});
    return"";
  }
  const page =yield select(x => x.pageList)
  yield put({type:"SET_LOADING_BAR",payload:true});

  const changeType=payload?.changeType
  //alert("empieza fetch")
  try {
  //Corroboramos que haya cambios antes de actualizar
  let lastChangeDynamo="xx";
    try {
      lastChangeDynamo = yield API.graphql(graphqlOperation(lastChange))
    } catch (error) {
      console.log(error)
      //If dynamo row not found, create it
      if (error?.errors?.length>0&& error?.errors[0].errorType=="MappingTemplate") {
        //Get username from amplify auth
        const response=yield API.graphql(graphqlOperation(modifyLastChange))
        lastChangeDynamo=response.data.modifyLastChange.id
      }
      
    }
  if (lastChangeDynamo.data.lastChange.id!=lastChangeAux||changeType) {
    yield put({type:"SET_LAST_CHANGE",payload:lastChangeDynamo.data.lastChange.id});
  try {
    const responseReservas = yield API.graphql({
      query: reservationList,
      variables:{type},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    //alert("termina fetch")
    yield put({type:'RELOAD_CASH_MOVEMENT'})
    const listaReservas=responseReservas.data.reservationList;
    yield put({type:"LISTA_RESERVAS",payload:listaReservas});
    yield put({type:'RELOAD_STATISTICS_BY_DATE'})
    yield put({type:'PAGE_LISTINGS_PRE_RESERVATION',payload:page})
  }
  catch (error) {
    console.log("Failed to fetch reservations:", error);
    //alert("Error al cargar reservas, revise su conexion a internet");
    }
  }
    yield put({type:"SET_LOADING_BAR",payload:false});

  } catch (error) {
    try {
      console.log(`Error ${error}`)
      console.log(`Error ${JSON.stringify(error)}`)
    } catch (error) {
      console.log(`Error ${error}`)
    }
  }
}

function* reloadReservationList() {
  const {tipoPrecio,listaGeneralReservas,precios} =yield select(x => x.mainReducer)
  if (tipoPrecio&&listaGeneralReservas) {
    const precioElegido=precios.find(x=>x.type==tipoPrecio)
    if (precioElegido) {
      yield put({type:"MAIN_LOADING",payload:true});
      yield *reloadReservations({changeType:true})
      yield put({type:"MAIN_LOADING",payload:false});

      //yield put({type:'SET_LISTA_RESERVAS',payload:listaGeneralReservas.filter(x=>(x.roomsList==0&&precioElegido.withoutPlace)||x.roomsList.find(y=>y&&y.type==tipoPrecio))});
    }
  }
}

function* reloadDeptosList() {
  const {tipoPrecio,listaGeneralDeptos} =yield select(x => x.mainReducer)
  if (tipoPrecio&&listaGeneralDeptos) {
    yield put({type:'SET_LISTA_DEPTOS',payload:listaGeneralDeptos.filter(x=>x.type==tipoPrecio)});
  }
}

function* reloadCheckout() {
  const {tipoPrecio,precios} =yield select(x => x.mainReducer)

  if (precios&&tipoPrecio&&precios.length>0) {
    const currentPrice=precios.find(x=>x.type==tipoPrecio);
    yield put({type:'SET_CHECKOUT_TIME',payload:currentPrice.checkoutTime});
    yield put({type:'SET_PRE_RESERVATION',payload:currentPrice.preReservation});
    yield put({type:'SET_WITHOUT_PLACE',payload:currentPrice.withoutPlace});
    yield put({type:'SET_DIA_PLAYA',payload:currentPrice.diaPlaya});
    yield put({type:'SET_IS_PRE_RESERTVATION',payload:currentPrice.isPreReservation});
    if(currentPrice.userToken){
      yield put({type:'SET_API_BILL_SESSION',payload:{
        userToken:currentPrice.userToken,
        apiKey:currentPrice.apiKey,
        apiToken:currentPrice.apiToken,
        pointSell:currentPrice.pointSell,
        billTypes:currentPrice.billTypes,
      }});
    }
    //yield put({type:'SET_OTAS',payload:currentPrice.otas??[]});
  }
}

function* reloadRooms() {
  //Actualiza el título del documento usando la API del navegador
  const response = yield API.graphql({
      query: roomsList,
      variables:{},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
  const listaDeptos=response.data.roomsList;
  yield put({type:'SET_LISTA_GENERAL_DEPTOPS',payload:listaDeptos});
}

function* listaReservas({payload}) {

  if (payload==undefined){
    const listaReservas=yield select(x=>x.mainReducer.listaReservas)
    payload=listaReservas;
  }
  const listaDeptos=yield select(x=>x.mainReducer.listaGeneralDeptos);
  const payloadAux=payload.map(x=>MejorarIngreso(x,listaDeptos))
  yield put({type:'SET_LISTA_GENERAL_RESERVAS',payload: payloadAux});
}

//Convierte precios de lista a objeto para buscar mas rapido
function* reloadPricesByDate({payload:{typeList,from,to}}) {
  let acom=0
  let typePriceList=[]
  let otaList=[]
  let promiseList=[]
  let otaItems=[]
 for (let type of typeList) {
  if (type.otas&&type.otas.length>0) {
    for (let ota of type.otas) {
      const newType=JSON.parse(JSON.stringify(type))
      newType.type=`${type.type}-${ota.type}`
      newType.name=ota.name
      otaItems=[...otaItems,newType]
    }
    }
 }

const typeAux=[...typeList,...otaItems]
  for (let type of typeAux) {
    promiseList=[...promiseList,API.graphql(graphqlOperation(getAllPrices,{type:type.type,from,to}))]
  }
  
  const responseList=(yield Promise.all(promiseList)).map(x=>x.data.getAllPrices);
  for (let response of responseList) {
    const type=typeAux[acom].type.split('-')[0];
    const realType=typeAux[acom].type;

    const priceList=response;
    const obj={}
    const availability={}
    const autoUpdate={}
    for (let priceItem of priceList) {
      const p={}
      const {date,price}=priceItem;
      if (price) {
        const priceParsed=JSON.parse(price)
        for (const value of Object.keys(priceParsed)) {
          p[value]=priceParsed[value]
        }
        obj[date]=p
      }
      
        availability[date]=priceItem.availability
        autoUpdate[date]=priceItem.autoUpdateAvailability
      
    }
    const listaFechas=ListaFechas(from,to)
    const precios=(yield select(x=>x.mainReducer.precios))
    //Manana arreglar esta parte
    const priceListAux=precios.find(y=>y.type==type).priceList
    const isOnline=precios.find(y=>y.type==type).isOnline
    const ret=priceListAux.map(x=>{
      return({name:x.name,list:listaFechas.map(y=>({fecha:y,precio:obj[y]?obj[y][x.name]?obj[y][x.name]:0:0}))})})
    const availabilityRet=listaFechas.map(x=>({fecha:x,disponibilidad:availability[x]??0}))
    const autoAjustarRet=listaFechas.map(x=>({fecha:x,autoAjustar:autoUpdate[x]??0}))
    if (type==realType) {
      typePriceList=[...typePriceList,{precios:ret,disponibilidad:availabilityRet,type:realType,isOnline,isOta:false}]
    }
    else{
      const otaId=typeAux[acom].type.split('-')[1];
      const name=Normalizar(typeAux[acom].name)
      otaList=[...otaList,{precios:ret,disponibilidad:availabilityRet,type:realType,name,isOnline,isOta:true,autoAjustar:autoAjustarRet}]
    }
    acom++
  }

  yield put({type:'PRECIOS_X_FECHA',payload:typePriceList});
  yield put({type:'PRECIOS_X_FECHA_OTA',payload:otaList});
  //yield put({type:'DISPONIBILIDAD_X_FECHA',payload:availabilityRet});
}

//Reloads only if lastpricechange is different
function* reloadPriceList() {
  const {lastPriceChange}=yield select(x=>x.mainReducer)
  let response;
  try {
    response = yield API.graphql({
      query:priceLastChange,
      variables:{},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
  } catch (error) {
    try {
      console.log("error precios");
      if (error?.errors&&error?.errors[0].message=="Network Error") { 
        return;
      }
      yield API.graphql(graphqlOperation(modifyPriceLastChange))
      response = yield API.graphql({
        query:priceLastChange,
        variables:{},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
    } catch (error) {
      return;
    }

  }

  const priceLastChangeResponse=response.data.priceLastChange.id;
  if (priceLastChangeResponse!=lastPriceChange) {
    console.log('Reloading price list')
    yield put({type:'SET_LAST_PRICE_CHANGE',payload:priceLastChangeResponse});
    yield reloadPricesByDate({payload:{typeList:yield select(x=>x.mainReducer.precios),from:moment().add(-60,'days').format('YYYY-MM-DD'),to:moment().add(200,'days').format('YYYY-MM-DD')}})
  }
}

//Convierte precios de lista a objeto para buscar mas rapido
function* initPrices({payload:{from,to}}) {
  yield put({type:"MAIN_LOADING",payload:true});
  const response = yield API.graphql({
    query: getPrices,
    variables:{},
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })
  const prices=(JSON.parse(JSON.parse(response.data.getPrices)));
  console.time('initPrices')
  yield put({type:"SET_TIPO_PRECIO",payload:prices[0].type})
  yield put({type:'SET_PRECIOS',payload:prices});
  yield *reloadPricesByDate({payload:{typeList:prices,from,to}})
  yield *reloadCheckout()
  yield *reloadAll()
  console.timeEnd('initPrices')
}

//Convierte precios de lista a objeto para buscar mas rapido
function* modifyAutoUpdate({payload:{from,to,autoUpdateAvailability,tipoPrecio}}) {
  yield put({type:'PRICES_LOADING',payload:true})
  const {preciosXFecha,preciosXFechaOta}=yield select(x=>x.mainReducer)
  const preciosXFechaTotal=[...preciosXFecha,...preciosXFechaOta]
  const autoUpdateXFecha=JSON.parse(JSON.stringify(preciosXFechaTotal.find(x=>x.type==tipoPrecio).autoAjustar))
  try {
    let listaPromesas=[]
    const listaFechas=ListaFechas(from,to)
    for (let fecha of listaFechas) {
      const prices={}
      for (let precio of preciosXFechaTotal.find(x=>x.type==tipoPrecio).precios) {
         const pAux=precio.list.find(x=>x.fecha==fecha)?.precio;
          if (pAux!=undefined&&pAux!=0) {
            prices[precio.name]=pAux;
        }
      }
      const availability=preciosXFechaTotal.find(x=>x.type==tipoPrecio).disponibilidad.find(x=>x.fecha==fecha).disponibilidad;
      console.log({type:tipoPrecio,availability,autoUpdateAvailability,date:fecha,price:JSON.stringify(prices)},)
      const response = API.graphql({
        query: addPrice,
        variables:{type:tipoPrecio,availability,autoUpdateAvailability,date:fecha,price:JSON.stringify(prices)},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      listaPromesas=[...listaPromesas,response];
    }

    const rta=yield Promise.all(listaPromesas);
    //Recarga la nueva disponibilidad
    const newAvailabilities=rta.map(x=>x.data.addPrice)
    for (let newAvailability of newAvailabilities) { 
      preciosXFechaTotal.find(x=>x.type==tipoPrecio).autoAjustar.find(x=>x.fecha==newAvailability.date).autoAjustar=newAvailability.autoUpdateAvailability
    }
    yield put({type:'AUTOAJUSTE_X_FECHA',payload:autoUpdateXFecha});
    yield API.graphql(graphqlOperation(modifyPriceLastChange))


  } catch (error) {
    if (error?.errors[0].message=="Network Error") { 
      alert('Error: No hay conexion a internet');
  }
  else{
    alert("Error al modificar auto ajuste")
  }
  }
  yield put({type:'PRICES_LOADING',payload:false})
}

//Convierte precios de lista a objeto para buscar mas rapido
function* modifyAvailability({payload:{from,to,availability,tipoPrecio}}) {
  yield put({type:'PRICES_LOADING',payload:true})
  const {preciosXFecha,preciosXFechaOta}=yield select(x=>x.mainReducer)
  const preciosXFechaTotal=[...preciosXFecha,...preciosXFechaOta]
  const disponibilidadXFechaAux=JSON.parse(JSON.stringify(preciosXFechaTotal.find(x=>x.type==tipoPrecio).disponibilidad))
  try {
    let listaPromesas=[]
    const listaFechas=ListaFechas(from,to)
    for (let fecha of listaFechas) {
      const prices={}
      for (let precio of preciosXFechaTotal.find(x=>x.type==tipoPrecio).precios) {
         const pAux=precio.list.find(x=>x.fecha==fecha)?.precio;
          if (pAux!=undefined&&pAux!=0) {
            prices[precio.name]=pAux;
        }
      }
      const autoUpdateAvailability=1;
      const response = API.graphql({
        query: addPrice,
        variables:{type:tipoPrecio,availability,autoUpdateAvailability,date:fecha,price:JSON.stringify(prices)},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      listaPromesas=[...listaPromesas,response];
    }

    const rta=yield Promise.all(listaPromesas);
    //Recarga la nueva disponibilidad
    const newAvailabilities=rta.map(x=>x.data.addPrice)
    for (let newAvailability of newAvailabilities) { 
      preciosXFechaTotal.find(x=>x.type==tipoPrecio).disponibilidad.find(x=>x.fecha==newAvailability.date).disponibilidad=newAvailability.availability
    }
    yield put({type:'DISPONIBILIDAD_X_FECHA',payload:disponibilidadXFechaAux});
    yield API.graphql(graphqlOperation(modifyPriceLastChange))

  } catch (error) {
    if (error?.errors[0].message=="Network Error") { 
      alert('Error: No hay conexion a internet');
  }
  else{
    alert("Error al modificar disponibilidad")

  }
  }
  yield put({type:'PRICES_LOADING',payload:false})
}
//Convierte precios de lista a objeto para buscar mas rapido
function* modifyPrices({payload:{from,to,item,price,tipoPrecio}}) {
  yield put({type:'PRICES_LOADING',payload:true})
  const {preciosXFecha,preciosXFechaOta}=yield select(x=>x.mainReducer)
  const isOta=tipoPrecio.split('-').length!=1;
  const preciosXFechaTotal=isOta?preciosXFechaOta:preciosXFecha
  const preciosXFechaAux=JSON.parse(JSON.stringify(preciosXFechaTotal))
  try {
    let listaPromesas=[]
    const listaFechas=ListaFechas(from,to)
    for (let fecha of listaFechas) {
      const prices={}
      for (let precio of preciosXFechaAux.find(x=>x.type.trim()==tipoPrecio.trim()).precios) {
        if (precio.name!=item) {
         const pAux=precio.list.find(x=>x.fecha==fecha)?.precio;
          if (pAux!=undefined&&pAux!=0) {
            prices[precio.name]=pAux;
          }
        }
      }
      prices[item]=parseInt(price)
      const availability=preciosXFechaAux.find(x=>x.type==tipoPrecio).disponibilidad.find(x=>x.fecha==fecha).disponibilidad;
      const autoajustarExist=preciosXFechaAux.find(x=>x.type==tipoPrecio).autoAjustar;
      const autoUpdateAvailability=autoajustarExist?preciosXFechaAux.find(x=>x.type==tipoPrecio).autoAjustar.find(x=>x.fecha==fecha).autoAjustar:0;

      const response = API.graphql({
        query: addPrice,
        variables:{type:tipoPrecio,availability,autoUpdateAvailability,price:JSON.stringify(prices),date:fecha},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      listaPromesas=[...listaPromesas,response];
    }
    const rta=yield Promise.all(listaPromesas);
    console.log(rta)
    const newPrices=rta.map(x=>x.data.addPrice)
    for (let newPrice of newPrices) { 
      preciosXFechaAux.find(x=>x.type==tipoPrecio).precios.find(x=>x.name==item).list.find(x=>x.fecha==newPrice.date).precio=JSON.parse(newPrice.price)[item]
    }
    if (isOta) {
      yield put({type:'PRECIOS_X_FECHA_OTA',payload:preciosXFechaAux});

    }
    else{
      yield put({type:'PRECIOS_X_FECHA',payload:preciosXFechaAux});

    }
    yield put({type:'PRICES_LOADING',payload:true})
    yield API.graphql(graphqlOperation(modifyPriceLastChange))

  } catch (error) {
    if (error?.errors[0].message=="Network Error") { 
      alert('Error: No hay conexion a internet');
    }    
    else{
      alert("Error al modificar precios")
    }
  }
  yield put({type:'PRICES_LOADING',payload:false})}

function* canceledService({payload:{dispatch,serviceId,isCanceled,reservationId}}) {
  yield put({type:'SET_PAYMENT_LOADING',payload:true});
  const {listaGeneralDeptos,listaReservas}=yield select(x=>x.mainReducer)
  let reservaAux=JSON.parse(JSON.stringify(yield select(x=>x.mainReducer.reserva)))

  const cantidad=reservaAux.servicesList.find(x=>x.serviceId==serviceId).quantity
  console.log(cantidad)
  reservaAux.checkoutEstimated=moment(`${moment(reservaAux.checkoutEstimated).add(isCanceled?-cantidad:cantidad,'days').format('YYYY-MM-DD')}T${reservaAux.checkoutEstimated.split('T')[1]}`).format();
  reservaAux.servicesList.find(x=>x.serviceId==serviceId).canceled=isCanceled;

  // Actualiza el título del documento usando la API del navegador
  const response = yield API.graphql({
      query: cancelService,
      variables:{serviceId,canceled:isCanceled},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

  const ingresoMejorado=MejorarIngreso(reservaAux,listaGeneralDeptos)
  yield put({type:'SET_RESERVA',payload:ingresoMejorado});
  const listaNueva=listaReservas.filter(x=>x.reservationId!=ingresoMejorado.reservationId)
  yield dispatch({type:"LISTA_RESERVAS",payload:[...listaNueva,ingresoMejorado]})
  yield put({type:'SET_PAYMENT_LOADING',payload:false});
}

// function* reloadPricesByDate({payload:{from,to,tipoPrecio}}) {
  
// }
function* reloadFromLocalStorage() {
  const listaGeneralReservas= yield localStorage.getItem('listaGeneralReservas')  
  if (listaGeneralReservas) {
    try {
      //alert("Cargamos local storage")
      yield put({type:"LISTA_RESERVAS",payload:JSON.parse(listaGeneralReservas)})
      //alert("Vaciamos local storage")


    } catch (error) {
      //alert("Error local storage")
    }
   return true
  }
  else{
    return false
  }
}

function* initCashMovements() {
  try {
    yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:true});
  
    const {currentCashName}=yield select(x=>x.mainReducer)
    yield reloadBuildNumber({payload:{cashName:currentCashName,init:true}})
    const {currentBuildNumber}=yield select(x=>x.mainReducer)
  
    const response=yield API.graphql({
        query: getAllCashMovements,
        variables:{buildNumber:currentBuildNumber,cashName:currentCashName},//
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
  
    const listaMovimientos=response.data.getAllCashMovements
  
    yield put({type:'SET_CASH_MOVEMENTS',payload:listaMovimientos});
    yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:false});
  } catch (error) {
    
  }

}

function* reloadCashMovements() {
  yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:true});
  
  const {currentBuildNumber,currentCashName}=yield select(x=>x.mainReducer)
  try {

  const response=yield API.graphql({
      query: getAllCashMovements,
      variables:{buildNumber:currentBuildNumber,cashName:currentCashName},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    const listaMovimientos=response.data.getAllCashMovements

    yield reloadBuildNumber({payload:{cashName:currentCashName}})
    yield put({type:'SET_CASH_MOVEMENTS',payload:listaMovimientos});
    yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:false});
  } catch (error) {
    console.log("FAILED TO RELOAD_CASH_MOVEMENT",error)
  }

}

function* reloadReservationListScheduler({payload:{startDate,endDate}}) {
  yield put({type:'MAIN_LOADING',payload:true})  
  const type =yield select(x => x.tipoPrecio)
  const {listaGeneralDeptos}=yield select(x=>x.mainReducer)
  const listaFechas=ListaFechas(startDate,endDate,4)
  const listaFechasLess=[...listaFechas];
  listaFechasLess.pop();
  const listaPromesas=listaFechasLess.map((x,i)=>
     API.graphql({
      query: reservationList,
      variables:{type,from:x,to:listaFechas[i+1]},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }))
  const queries=yield Promise.all(listaPromesas)
  const list=queries.reduce((acom,current)=>[...acom,...current.data.reservationList],[]).reduce((acom,current)=>acom.find(x=>x.reservationId==current.reservationId)?acom:[...acom,current],[])
  
  yield put({type:'ADD_LIST_TO_MAIN_RESERVATION_LIST',payload:list.map(x=>MejorarIngreso(x,listaGeneralDeptos))})
  //yield put({type:'SET_RESERVATION_LIST_SCHEDULER',payload:false})
  yield put({type:'MAIN_LOADING',payload:false})  

}

function* addCashMovements({payload:{saldo,type,closeCash,cashName,amount,isWithdrawal,concept,actorName,onComplete}}) {
  yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:true});
  try {
    const {lastBuildNumber,tipoPagos}=yield select(x=>x.mainReducer)
    yield reloadBuildNumber({payload:{cashName}})
    const {lastBuildNumber:lastBuildNumberAux,isCashClose}=yield select(x=>x.mainReducer)
    if (lastBuildNumber!=lastBuildNumberAux) {
      alert("No se pudo insertar debido a que esta caja esta cerrada")
    }
    else if (!closeCash&&isCashClose) {
      alert("La caja esta cerrada, abrila para poder insertar transacciones")
    }
    else{
      const buildNumber=lastBuildNumber+(closeCash?isCashClose?0:1:0);
      //Si se cierra la caja
      if (closeCash) {
        yield Promise.all(Object.keys(saldo).filter(x=>saldo[x]!=undefined&&saldo[x]>0).map((type,i)=>
          API.graphql({
            query: addCashMovement,
            variables:{buildNumber:lastBuildNumber,cashName,amount:saldo[type],type,closeCash:false,isWithdrawal:true,isMoneyLeft:false,concept:`${type} final`},//
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }))
        )
        
        yield API.graphql({
          query: addCashMovement,
          variables:{buildNumber:lastBuildNumber,cashName,amount,closeCash:true,isMoneyLeft:true,isWithdrawal,concept:`${tipoPagos[0].tipo} al cerrar la caja`},//
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })

        yield API.graphql({
          query: addCashMovement,
          variables:{buildNumber:lastBuildNumber,cashName,amount:0,closeCash:true,isMoneyLeft:false,isWithdrawal,concept:`Caja cerrada por ${actorName}`},//
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
      }

      //Si cerraron la caja
      if (closeCash){
        const {tipoPagos}=yield select(x=>x.mainReducer);
        yield API.graphql({
          query: addCashMovement,
          variables:{buildNumber:buildNumber,cashName,type:tipoPagos[0].tipo,isMoneyLeft:false,amount,closeCash:false,isWithdrawal:false,concept:`Caja abierta por ${actorName}`},//
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
      }
      else{
        yield API.graphql({
          query: addCashMovement,
          variables:{buildNumber:buildNumber,cashName,type,isMoneyLeft:false,amount,closeCash:false,isWithdrawal,concept},//
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
      }

    

      yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:false});
      onComplete();
      //yield API.graphql(graphqlOperation(modifyLastChange))
    }

    yield put({type:'INIT_CASH_MOVEMENT',payload:false});
  } catch (error) {
    yield put({type:'SET_CASH_MOVEMENT_LOADING',payload:false});
    console.log("FAILED TO ADD_CASH_MOVEMENT",error)
    alert("No se pudo cargar el movimiento de caja, revisa que estes conectado a internet")
  }
}

function* reloadBuildNumber({payload:{cashName,init}}) {
  try {
    const response=yield Promise.all([API.graphql({
      query: lastBuild,
      variables:{cashName},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }),API.graphql({
      query: lastMovement,
      variables:{cashName},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })]);
    yield put({type:'SET_LAST_BUILD',payload:response[0].data.lastBuild.buildNumber});
    yield put({type:'SET_CASH_CLOSE',payload:response[1].data.lastMovement.closeCash});
    if (init) {
      yield put({type:'SET_CURRENT_BUILD_NUMBER',payload:response[0].data.lastBuild.buildNumber});
    }
  } catch (error) {
    
  }

}
function* seeker({payload:{searchThing}}) {
  yield put({type:"SET_SEARCH_LOADING",payload:true})
  try {
    const response=yield API.graphql({
      query: search,
      variables:{search:searchThing},//
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    const {listaGeneralDeptos}=yield select(x=>x.mainReducer)
    yield put({type:"SET_SEARCH_RESULT",payload:response.data.search.reverse().map((x,i)=>
      {
        let customerFound=x.customersList.find(y=>
          y.fullName.toLowerCase().includes(searchThing)||
          y.dni?.toLowerCase().includes(searchThing))
        let ingresoAux;
          if (customerFound) {
            ingresoAux={...x,customersList:[customerFound,...x.customersList.filter(x=>customerFound.customerId!=x.customerId)]}
          }
          else{
            ingresoAux=x;
          }
        let title=<GetCard ingreso={MejorarIngreso(ingresoAux,listaGeneralDeptos)} i={i}></GetCard>
        let description=""
        return (
          {
            title,
            description
          }
        )
      })})

  } catch (error) {
    console.log(error)
  }
  yield put({type:"SET_SEARCH_LOADING",payload:false})
}

function* newReservation() {
  const {changeDateTime,diaPlaya}=yield select(x=>x.mainReducer)
    const checkoutEstimated=moment().add(-changeDateTime,'hours').add(diaPlaya?0:1,'days').set({hour:CalcularCheckoutTime()-3,minute:0,second:0,millisecond:0}).format();
    const checkinEstimated=moment().add(-changeDateTime,'hours').set({hour:CalcularCheckoutTime()-3,minute:0,second:0,millisecond:0}).format();
    //const habitacionesDisponibles=HabitacionesDisponibles(checkinEstimated,checkoutEstimated)
    const nuevaReserva= {
                "guests": 2,
                "children": 0,
                checkoutEstimated,
                checkinEstimated,
                servicesList:[],
                customersList:[],
                vehiclesList:[],
                nuevaReserva:true,
                nights:diaPlaya?0:1,
                state: "",
                way: "Presencial",
                roomsList:[],
      }
  yield put({type:'SET_RESERVA',payload:nuevaReserva})
  yield put({type:'SET_MODAL_RESERVA',payload:true})
}

function* reloadStatisticsByDate() {
  const {statisticsDate,statisticsMonth}=yield select(x=>x.mainReducer)
  yield put({type:'SET_STATISTICS_BY_DATE_LOADING',payload:true})
    try {
      if (statisticsMonth&&statisticsDate) {
        const found=statisticsMonth.find(x=>x.date==statisticsDate);
        if (found) {
          yield put({type:'SET_STATISTICS_BY_DATE',payload:found})
        }
        else{
          yield reloadStatisticsByMonth()
          const {statisticsMonth}=yield select(x=>x.mainReducer)
          const rta=statisticsMonth.find(x=>x.date==statisticsDate);
          yield put({type:'SET_STATISTICS_BY_DATE',payload:rta})
        }
      }
    } catch (error) {
    }
  yield put({type:'SET_STATISTICS_BY_DATE_LOADING',payload:false})

}

function* reloadStatisticsByMonth(changeType) {
  const {tipoPrecio,statisticsDate,generalStatisticsByDate}=yield select(x=>x.mainReducer)
  yield put({type:'SET_STATISTICS_BY_MONTH_LOADING',payload:true})
    try {
      if (tipoPrecio&&statisticsDate) {
        if (changeType||!generalStatisticsByDate||moment(statisticsDate).format('MM/YYYY')!=moment(generalStatisticsByDate.date).format('MM/YYYY')) {
          const response=yield API.graphql({
            query: getMonthStatistics,
            variables:{from:moment(statisticsDate).startOf('month'),to:moment(statisticsDate).endOf('month'),type:tipoPrecio},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          })
          const month=JSON.parse(response.data.getMonthStatistics);
          yield put({type:'SET_STATISTICS_MONTH',payload:month})
        }
        const {statisticsMonth}=yield select(x=>x.mainReducer)
        yield put({type:'SET_STATISTICS_BY_DATE',payload:statisticsMonth.find(x=>x.date==statisticsDate)})
      }
    } catch (error) {
      console.log("Failed to reload statistics by month",error)
      //Network error alert
        if (error?.errors[0].message=="Network Error") { 
          alert('Error: No hay conexion a internet');
      }
    }
  yield put({type:'SET_STATISTICS_BY_MONTH_LOADING',payload:false})
}

function* pageListing({payload}) {
  yield put({type:'SET_LISTING_LOADING',payload:true})
  yield put({type:'SET_PAGE_LIST',payload})
  yield *reloadCheckoutList();
  yield put({type:'SET_LISTING_LOADING',payload:false})
}

function* reloadCheckoutList() {
  const {pageList,regularCustomer}=yield select(x=>x.mainReducer)
  yield put({type:'SET_LISTING_LOADING',payload:true})
    try {
      const response=yield API.graphql({
        query: getCheckoutList,
        variables:{page:pageList,regularCustomer:regularCustomer?false:true},//
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      yield put({type:'SET_CHECKOUT_LIST',payload:response.data.getCheckoutList})
    } catch (error) {
      console.log(error)
    }
  yield put({type:'SET_LISTING_LOADING',payload:false})
}

function* reloadPreReservation() {
  const {pageList}=yield select(x=>x.mainReducer)
  yield put({type:'SET_LISTING_LOADING',payload:true})
    try {
      const response=yield API.graphql({
        query: getStatusList,
        variables:{page:pageList,status:'preingreso'},//
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      console.log(response)
      yield put({type:'PRE_RESERVATION_LIST',payload:response.data.getStatusList})
    } catch (error) {
      console.log(error)
    }
  yield put({type:'SET_LISTING_LOADING',payload:false})
}

function* preReservation({payload}) {
  yield put({type:'SET_PRE_RESERVATION_LIST',payload:payload.map(x=>MejorarIngreso(x)).sort((a, b) => moment(b.created).diff(moment(a.created)))})
}


function* reloadPaymentListStatistics({from,to}) {
  const {paymentListDay}=yield select(x=>x.mainReducer)
  if (paymentListDay) {
    yield put({type:'SET_PAYMENT_STATISTICS_LOADING',payload:true})
    const pagos=(yield ObtenerListaPagosFechas(moment(from??paymentListDay).startOf('day').format(),moment(to??paymentListDay).endOf('day').format())).map(x=>({...x,date:moment(x.date).add(3,"hour")}))
    yield put({type:'SET_PAYMENT_LIST_STATISTICS',payload:pagos})
    yield put({type:'SET_PAYMENT_STATISTICS_LOADING',payload:false})
  }
}

function* openReservation({payload}) {
  const {listaGeneralDeptos,listaGeneralReservas}=yield select(x=>x.mainReducer)
  const reservationFound=listaGeneralReservas.find(x=>x.reservationId==payload);
  if(reservationFound){
    yield put({type:'SET_RESERVA',payload:reservationFound})
    yield put({type:'SET_MODAL_RESERVA',payload:true})
  }
  else{
    yield put({type:"MAIN_LOADING",payload:true});
    try {
      //reservation
      const response=yield API.graphql({
        query: reservation,
        variables:{reservationId:payload},//
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      if (response.data.reservation.length==1) {
        const reservation=response.data.reservation[0]
        yield put({type:'SET_RESERVA',payload:MejorarIngreso(reservation,listaGeneralDeptos)})
        yield put({type:'SET_MODAL_RESERVA',payload:true})
    }  
  yield put({type:"MAIN_LOADING",payload:false});
    } catch (error) {
      alert("Error al cargar la reserva")
    }
  }

}


function* reloadBillList({payload}) {
  try{
    const response= yield API.graphql(graphqlOperation(billsByReservation,{reservationId:payload}))
    yield put({type:"SET_BILL_LIST",payload:[...response.data.billsByReservation]})
  }
  catch(error){
    console.log(error)
  }

}

function* duplicateReservation({payload}) {
  const {checkoutTime,diaPlaya}=yield select(x=>x.mainReducer)
  const checkoutEstimated=moment().add(1,'days').set({hour:checkoutTime-3,minute:0,second:0,millisecond:0}).format();
  const checkinEstimated=moment().set({hour:checkoutTime-3,minute:0,second:0,millisecond:0}).format();
  const vehiclesList=JSON.parse(JSON.stringify(payload.vehiclesList))
  vehiclesList.forEach(element => {
    delete element.tableData;
  });
  const customersList=payload.customersList.map(x=>{const {age,customerId,...aux}=x;return(aux)})
  const nuevaReserva= {
    guests: 2,
    children: 0,
    checkoutEstimated,
    checkinEstimated,
    servicesList:[],
    customersList,
    vehiclesList,
    nuevaReserva:true,
    nights:diaPlaya?0:1,
    state: "",
    way: "Presencial",
    roomsList:[],
    }
  yield put({type:"SET_RESERVA",payload:nuevaReserva});
}

function* uploadBill() {
  if(canBill()){
    
    yield put({type:"SET_LOADING_BILL",payload:true});
    const {printer,togglePrinter} = yield select(x=>x.printerReducer)
    const sellPoint=yield select(x=>x.billReducer?.sellPoint)
    const state=yield select(x=>x.billReducer)
    const billList=yield select(x=>x.billReducer.billList);
    const documentNumber = state.customer.isConsumidorFinal ? 0 : state.customer.dni
    const billAux={
      ...state.billInfo,
      listProduct: state.itemsList,
      name: state.customer.fullName,
      email: state.customer.email,
      documentNumber: documentNumber,
      reservationId: state.reserva.reservationId,
      pointSell:sellPoint}
    delete billAux.total
    const userToken="a";
    const apiKey="a";
    const apiToken="a";
    
    try{
      if(togglePrinter){
        const response=yield API.graphql(graphqlOperation(makeBill,{userToken,apiKey,apiToken,bill:billAux}))
        if(printer.isConnected&&printer.printTicket){
          yield printPOS({...JSON.parse(response.data.makeBill),bill:billAux},"ticketb")
        }
        if(response.data.makeBill){
          console.log(billList)
          yield put({type:"SET_BILL_LIST",payload:[...billList,JSON.parse(response.data.makeBill)]});
        }
      }
      yield put({type:"SET_LOADING_BILL",payload:false});
    }
    catch(error){
      console.log(error)
      yield put({type:"SET_LOADING_BILL",payload:false});
      alert(billAux.cancelBillNumber?`Error al crear nota de credito`:`Error al crear la factura`)

    }
  }
}

function* cancelBill({payload:cancelBillNumber}){
  const {billList}=yield select(x=>x.billReducer)

  const billToCancelAux=billList.find(x=>x.comprobanteNro==cancelBillNumber)
  if(billToCancelAux){
    let billToCancel={...billToCancelAux}

    yield put({type:"SET_LOADING_BILL",payload:true});
    const userToken="a";
    const apiKey="a";
    const apiToken="a";
    billToCancel.typeBill=billlToCreditNote(billToCancel.typeBill)
    delete billToCancel.billLink
    delete billToCancel.specificUsername
    delete billToCancel.date
    delete billToCancel.afipQR
    delete billToCancel.afipBarCode
    delete billToCancel.cae
    delete billToCancel.dateExpireCae
    billToCancel.cancelBillNumber=billToCancel.comprobanteNro
    delete billToCancel.comprobanteNro
    console.log(billToCancel)
    try{
      const response=yield API.graphql(graphqlOperation(makeBill,{userToken,apiKey,apiToken,bill:billToCancel}))
      if(response.data.makeBill){
        yield put({type:"SET_BILL_LIST",payload:[...billList,JSON.parse(response.data.makeBill)]});
      }
      yield put({type:"SET_LOADING_BILL",payload:false});
    }
    catch(error){
      yield put({type:"SET_LOADING_BILL",payload:false});
      alert("Error al cancelar la factura")
    }
  }
}

function* loadUserConfig(){
  const userConfig=JSON.parse(localStorage.getItem("userConfig"))
  if(userConfig&&userConfig.lastUpdate&&userConfig.lastUpdate>moment().subtract(1,'day').valueOf()){
    return userConfig
  }
  else{
    const response=JSON.parse((yield API.graphql(graphqlOperation(getUserInfo))).data.getUserInfo)
    if(response){
      yield put({type:"SET_BILLING_INFO",payload:response.billingInfo})
      yield put({type:"INIT_PRINTER",payload:response.printerInfo})
      yield put({type:"SET_TOGGLE_PRINTER",payload:response.togglePrinter})
      yield put({type:"SET_TOGGLE_BILLING",payload:response.toggleBilling})
      if (response.togglePrinter) {
        yield* connectPrinter()
      }
      // console.log(response.billingInfo,response.printerInfo)
      localStorage.setItem("userConfig",JSON.stringify(response))
      return response
    }
    else{
      return null
    }
  }
}
function* canBill() {
  const {billInfo}=yield select(x=>x.billReducer)
  //Check if all billInfo fields are set and dateFrom and to are valid dates and from is before to and bring alert for all posible errors
  if (
    billInfo.typeBill &&
    billInfo.payCondition &&
    billInfo.dateFrom &&
    billInfo.dateTo &&
    billInfo.total > 0 
  ) {
    if (
      moment(billInfo.dateFrom).isSameOrBefore(
        moment(billInfo.dateTo)
      )
    ) {
      return true;
    } else {
      alert("La fecha de inicio debe ser menor o igual a la de fin")
    }
  } else {
    alert("Debe completar todos los datos de la factura")
  }
  return false;
}

function* cuitInfo({payload}) {
  yield put({type:"SET_LOADING_CUIT_SEARCH",payload:true});
  try {
    const cuit=payload.split("-").join("");
    const response=yield fetch(`https://afip.tangofactura.com/Rest/GetContribuyenteFull?cuit=${cuit}`)
    const data=yield response.json()
    if(data.errorGetData){
      alert(data.errorMessage)
    }
    else{
      yield put({type:"SET_CUSTOMER",payload:{
        id:-1,
        fullName:data.Contribuyente.nombre,
        dni:cuit,
        age:"",
        isConsumidorFinal:false,
        searchedCuit:true,
      }})
    }
  } catch (error) {

  }

  yield put({type:"SET_LOADING_CUIT_SEARCH",payload:false});
}

// use them in parallel
export default function* rootSaga() {
    yield takeLeading('RELOAD_RESERVATIONS', reloadReservations)
    yield takeLeading('RELOAD_ROOMS', reloadRooms)
    yield takeLeading('RELOAD_ALL', reloadAll)
    yield takeLatest('LISTA_RESERVAS', listaReservas)

    //Se usa cuando se cambia el tipo de habitacion camping/dormis
    yield takeLatest('LISTA_RESERVAS_BY_TYPE', reloadReservationList)
    yield takeLatest('LISTA_DEPTOS_BY_TYPE', reloadDeptosList)
    yield takeLatest('RELOAD_CHECKOUT_TIME', reloadCheckout)
    yield takeLatest('RELOAD_LAST_BUILD_TIME', reloadBuildNumber)
    
    //Prices
    yield takeLatest('INIT_PRICES', initPrices)
    yield takeLatest('RELOAD_PRICE_LIST', reloadPriceList)
    yield takeLatest('INIT_CASH_MOVEMENT', initCashMovements)
    yield takeLatest('RELOAD_CASH_MOVEMENT', reloadCashMovements)

    //Avaiability
    yield takeLatest('MODIFY_AVAILABILITY', modifyAvailability)
    yield takeLatest('MODIFY_PRICES', modifyPrices)
    yield takeLatest('CANCEL_SERVICE', canceledService)
    yield takeLatest('ADD_CASH_MOVEMENT', addCashMovements)
    yield takeLatest('MODIFY_AUTOUPDATE', modifyAutoUpdate)

    //Manana intentar apretar el boton de facturrar y ver que pasa

    //Bill
    yield takeLatest('MAKE_BILL', uploadBill)
    yield takeLatest('CANCEL_BILL', cancelBill)
    yield takeLatest('CUIT_INFO', cuitInfo)

    //Statistics
    yield takeLatest('RELOAD_STATISTICS_BY_DATE', reloadStatisticsByDate)
    yield takeLatest('RELOAD_STATISTICS_BY_MONTH', reloadStatisticsByMonth)
    yield takeLatest('RELOAD_PAYMENT_LIST_STATISTICS', reloadPaymentListStatistics)

    //Pre Reservation
    yield takeLatest('PAGE_LISTINGS_PRE_RESERVATION', reloadPreReservation)
    yield takeLatest('PRE_RESERVATION_LIST', preReservation)

    //Reservations
    yield takeLatest('OPEN_RESERVATION', openReservation)
    yield takeLatest('RELOAD_RESERVATION_BILL', reloadBillList)

    //Search
    yield takeLatest('SEARCH', seeker)
    //States
    yield takeLatest('NEW_RESERVATION', newReservation)

    //Listings
    yield takeLatest('PAGE_LISTINGS', pageListing)
    yield takeLatest('RELOAD_CHECKOUT_LIST', reloadCheckoutList)

    //Load user config info
    yield takeLatest('LOAD_USER_CONFIG', loadUserConfig)

    //Utils
    yield takeLatest('DUPLICATE_RESERVATION', duplicateReservation)

    //Scheduler
    yield takeLatest('RELOAD_RESERVATION_LIST_SCHEDULER', reloadReservationListScheduler)
    }
  
