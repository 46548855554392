const defaultState={
    placeChoosed:null,
    showModal:false
};

export default function reducer(state=defaultState,{type,payload}){
    switch (type){
        case "CHOOSED_PLACE":
            return {...state,placeChoosed:payload,showModal:true};
        case "SHOW_MAP_MODAL":
            return {...state,showModal:payload};
        default:
            return state
    }
}