import {combineReducers,createStore,applyMiddleware } from 'redux'
import mainReducer from './reducers/mainReducer'
import printerReducer from './reducers/printerReducer'
import billReducer from './reducers/billReducer'
import mapReducer from './reducers/mapReducer'
import createSagaMiddleware from 'redux-saga'

//Sagas
import rootSaga from './sagas'
const sagaMiddleware = createSagaMiddleware()

// const store = createStore(mainReducer,applyMiddleware(sagaMiddleware))
const store = createStore(combineReducers({billReducer,mainReducer,printerReducer,mapReducer}),applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)


export default store